@import './consts.scss';
@import './fonts.scss';
@import './transitions.scss';
@import './common.scss';

// @import url('https://fonts.thu.fail/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&family=Noto+Serif+SC:wght@200;300;400;500;600;700;900&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,900&family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import url('https://fonts.thu.fail/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp&display=block');
@import url("https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css");

* {
  box-sizing: border-box;
}

html {
  overflow: auto;
  background: #FAFAFA;
}

body {
  margin: 0;
  padding: 0;
  min-width: 1350px;
  font-family: 'Source Sans Pro', 'Noto Sans SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: hidden;
}

code {
  @include monospace;
}

a {
  color: inherit;

  .link-indicator {
    color: $primary;
    filter: grayscale(1);
    transition: filter .2s ease;
  }

  &:hover .link-indicator {
    filter: none;
  }

  &.no-ul {
    text-decoration: none;
  }
}

.bottom-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $bottom-bar-height;

  z-index: 500;

  padding: 0 ($side-padding - 20px);
  padding-top: 39px;

  &-inner {
    position: relative;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;

    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    z-index: 0;

    background-color: rgba(255,255,255,.5);
    backdrop-filter: blur(2px);

    transition: background-color .2s ease, opacity .2s ease;
    opacity: 1;
  }

  &.top:before {
    background-color: change-color(#0f2585, $alpha: .5);
  }

  &.hidden:before {
    opacity: 0;
  }
}

.spanner {
  flex: 1;
}

.navs {
  height: 100%;
  display: inline-flex;
  align-items: flex-start;

  div {
    cursor: pointer;
    text-decoration: none;
    padding: 0 10px;

    font-family: 'GothamPro';
    font-weight: 400;
    font-size: 12px;

    opacity: 0.4;

    transition: color .2s ease, opacity .2s ease, padding .2s ease;
    letter-spacing: 1px;

    color: #111111;
    .bottom-bar.top & {
      color: white;
    }

    height: 100%;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;

    position: relative;

    &:hover {
      filter: none;
      padding: 0 20px;
      opacity: 1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      right: 0;
      bottom: 0;
      height: 3px;
      width: 38px;
      transform: translateX(-50%);
      border-radius: 1.5px;

      background-color: black;
      .bottom-bar.top & {
        background-color: white;
      }

      opacity: 0;
      transition: opacity .2s ease, background-color .2s ease;
    }

    &.active {
      padding: 0 20px;
      opacity: 1;

      &:after {
        opacity: 1;
      }
    }
  }
}

h1 {
  font-size: 24px;
  color: $primary;
  // @include header-shade($primary, 20px);
}

h2 {
  font-family: GothamPro;
  font-weight: 600;
  font-size: 27px;
  color: #111111;
  letter-spacing: 2px;
  text-align: center;
  // @include header-shade(black, 20px);
}

p {
  max-width: 800px;
  font-size: 18px;
  line-height: 28px;
}

.brand {
  font-size: 12px;
  @include monospace;
  color: white;

  display: inline-flex;
  align-items: center;
  z-index: 1;

  transition: opacity .2s ease, transform .2s ease;

  &.hidden {
    opacity: 0;
  }

}

img.brand {
  width: 140px;
  height: 15px;
}

.page {
}

.header {
  padding: (120px+$bottom-bar-height) ($side-padding - 20px) 40px ($side-padding - 20px);
  background: change-color($primary, $alpha: .1);
  margin-top: -$transition-dist;
}

.padded-page {
  padding: 20px $side-padding;
}

main {
  padding: 0 $side-padding;
}

.tile {
  padding: 20px;
  background: rgba(0,0,0,.05);
  height: 100%;

  h3 {
    font-size: 24px;
    margin: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  box-shadow: rgba(0,0,0,.3) 0 2px 6px;
}

.caret {
  transition: transform .2s ease;

  &.rotated {
    transform: rotate(180deg);
  }
}

hr {
  margin: 60px;
}

footer {
  padding: 0 $side-padding;
  height: 125px;
  background: #001894;
  display: flex;
  align-items: center;
  color: white;
  letter-spacing: 2px;
  font-size: 10px;
  padding-bottom: 50px;

  div:first-child {
    flex: 1;
  }

  div {
    display: flex;
    gap: 2em;
  }

  a {
    text-decoration: none;
  }
}